import { useState, ComponentProps } from 'react'
import type { ReactNode } from 'react'
import { Dialog } from '../apps/dialog'

type Props = {
  label: string
  style: string
  icon?: string
  children?: ReactNode
  position?: ComponentProps<typeof Dialog>['dialogPosition']
  title?: string
}

export function OpenDialog ({ label, style, icon, children, position, title }: Props) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className="inline-flex items-center">
        {icon ? <img src={icon} className="mr-1" /> : null}
        <button type="button" onClick={handleOpen} className={style}>
          {label}
        </button>
      </div>
      <Dialog open={isOpen} onClose={handleClose} dialogPosition={position || 'start'} showCloseButton={true} title={title}>
        {children}
      </Dialog>
    </>
  )
}
